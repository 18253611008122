<template>
    
    <div v-if="wins" class="h-auto w-full flex flex-col">
        <div class="h-auto p-2">
            <div class="h-auto w-full rounded-lg module p-2 flex flex-col">
                <div class="h-auto py-1">
                    <span class="text-green text-xs font-semibold">Wins</span>
                </div>
                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput"
                       class="w-full h-6 text-xs rounded bg-body flex flex-row justify-start
                       items-center px-2">
                </div>
                <div class="h-auto py-1 flex flex-row">
                    <div class="header-btn-gray"  v-for="(el, index) in typeDocs"
                         :key="index" :class="{ [el.class]: selectedExtensions === el.value }"
                         @click="filterByExtensions(el.value)">
                        <i :class="'mdi mdi-' + el.name"></i>
                    </div>
                </div>
                <div v-if="search(filteredWins).length > 0" class="h-auto w-full flex flex-col">
                    <win-card  v-for="(el,index) in search(filteredWins)" :key="index" :data="el"
                      view="mobile" />

                </div>

            </div>
            <div v-if="search(filteredWins).length === 0" class="h-auto w-full pt-4 flex-center">
                <span class="text-xs text-font-gray">There are no files of the selected extension</span>
            </div>
        </div>
    </div>
    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>

</template>

<script>

const WinCard = () => import('@/components/Globals/PersonalDashboard/Win/WinCard')
import {state} from '@/store';

export default {
    components: {
        WinCard
    },
    data() {
        return {
            wins: null,
            loading: false,
            filteredType: null,
            typeDocs: this.$typeDocs,
            searchInput: '',
            selectedExtensions: []
        }
    },
    methods: {
        filterByExtensions(extensions) {
            if (extensions === this.selectedExtensions) this.selectedExtensions = []
            else this.selectedExtensions = extensions
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Win&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                .then(response => {
                    this.wins = response.data
                    this.loading = false
                })
        },
        search(data) { return this.$search(data, this.searchInput) },
        extension(elem) {
            let result = ''
            result += this.$iconByExtension(elem.file)
            return result
        }
    },
    computed: {
        filteredWins() {
            if (this.selectedExtensions.length === 0) { return this.wins }
            return this.wins.filter(({ file }) => {
                const pathProps = file.split('.');
                const extension = pathProps[pathProps.length - 1];
                return this.selectedExtensions.includes(extension);
            });
        },
    },
    mounted(){
        this.load();
    }
}
</script>